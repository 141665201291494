<template>
  <div v-if="$isNotNullOrUndefined(listProgress)" class="a-list-progress">
    <div class="text-medium text-center mb-2 a-list-progress__text" :data-percentage="progressPct">
      {{ $t('youHaveSeen', 'Du har set') }} {{ listProgress.viewedItems }} {{ $t('of', 'af') }} {{ listProgress.totalCount }} {{ $t('products', '') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListProgress',
  props: {
    listProgress: {
      type: Object,
      default: function () {
        return null;
      }
    }
  },
  computed: {
    progressPct () {
      let hasValues =
        this.$isNotNullOrUndefined(this.listProgress) &&
        this.$isNotNullOrUndefined(this.listProgress.totalCount) &&
        this.$isNotNullOrUndefined(this.listProgress.viewedItems);
      return hasValues
        ? Math.ceil(
            (this.listProgress.viewedItems / this.listProgress.totalCount) * 100
          )
        : 0;
    }
  }
};
</script>
